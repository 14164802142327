<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ purchasePayment.vendorName }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ formatDate(purchasePayment.paymentDate) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ purchasePayment.voucherNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ purchasePayment.methodPayment }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ purchasePayment.bankCharge }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Round<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ purchasePayment.round }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Round Amount<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ purchasePayment.roundAmount }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ purchasePayment.remark }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-credit-card
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Invoice <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="purchasePayment.invoiceDtoList"
                        disable-pagination
                        hide-default-footer
                        dense
                      >
                        <template v-slot:item.no="{ item }">
                          {{ purchasePayment.invoiceDtoList.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.totalAmountAfterTax="{ item }">
                          {{ formatPrice(item.totalAmountAfterTax) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalInvoiceIDR="{ item }">
                          {{ formatPrice(item.totalInvoiceIDR) }}
                        </template>
                        <template v-slot:item.invoicePaid="{ item }">
                          {{ formatPrice(item.invoicePaid) }}
                        </template>
                        <template v-slot:item.totalInvoicePaidIDR="{ item }">
                          {{ formatPrice(item.totalInvoicePaidIDR) }}
                        </template>
                        <template v-slot:item.fullPayment="{ item }">
                          {{ item.fullPayment ? "YES" : "NO" }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6" class="pb-0"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Bank / Cash <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerBank"
                        :items="purchasePayment.accounts"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ purchasePayment.accounts.indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Paid</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(purchasePayment.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="approval('approved')">
                  <v-icon>
                    mdi-check
                  </v-icon>
                </v-btn>
              </template>
              <span>Approved</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- <v-row class="py-0 mb-2">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="approval('reject')">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Reject</span>
            </v-tooltip>
          </v-col>
        </v-row> -->
        <v-row class="py-0 mb-2">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-remark
      :dialog="dialogRemark"
      :form="body"
      @save="updateRemark"
      @close="close"
    ></dialog-remark>
  </form>
</template>

<script>
import moment from "moment";
import DialogRemark from "@/components/DialogRemark";

export default {
  name: "purchase-payment-confirm",
  props: {
    id: Number,
  },
  components: {
    DialogRemark,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice #",
        value: "invoiceNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "totalAmountAfterTax",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Invoice (RP)",
        value: "totalInvoiceIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Paid",
        value: "invoicePaid",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Paid (RP)",
        value: "totalInvoicePaidIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Full Payment",
        value: "fullPayment",
        sortable: false,
        divider: true,
      },
      {
        text: "PPH",
        value: "pphName",
        sortable: false,
        divider: true,
      },
    ],
    headerBank: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "AccountName",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    purchasePayment: {},
    body: {
      remark: "",
    },
    dialogRemark: false,
  }),

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    async getPurchasePayment() {
      await this.$store
        .dispatch("purchasePayment/getById", this.id)
        .then(response => (this.purchasePayment = response.data));
    },
    back() {
      this.$router.go(-1);
    },
    close() {
      this.dialogRemark = false;
    },
    approval(type) {
      if (type === "Rejected") {
        this.dialogRemark = true;
      } else {
        this.updateRemark(type, "");
      }
    },
    async updateRemark(type, item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchasePayment/approval", {
          transactionId: this.id,
          type: type,
          remark: item.remark,
          transactionType: item.transactionType,
        })
        .then(() => {
          this.$router.push({ name: "Detail Purchase Payment", params: { id: this.id } });
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getPurchasePayment();
  },
};
</script>

<style></style>
